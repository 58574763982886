.app-title-container{
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 5px white double;
    background-color: green;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .app-title{
    text-align: center;
    align-items: center;
    height: 40px;
  }
  
  .notes-container{
    padding: 10px;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    height: 400px;
    overflow-y: scroll;
    border: 5px white double;
    margin-top: 50px;
    background-color: rgb(202, 176, 176);
  }
  
  .note-editor-container{
    padding: 10px;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    height: 280px;
    border: 5px orange double;
    margin-top: 100px;
    background-color: rgb(202, 176, 176);
  }
  
  .add-note-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .footer-container{
    text-align: center;
  }