.card-note{
    border: 1px solid white;
    background-color: aliceblue;
    width: 450px;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  .card-note-title{
    margin: 0;
    font-weight: bold;
  }
  
  .card-note-content{
    margin: 0;
    font-size: 18px;
  }
  
  .delete-button{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }